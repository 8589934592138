import { SENTRY_DSN } from '@/config.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Sentry from '@sentry/vue'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import LoadScript from 'vue-plugin-load-script'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router.js'
import store from './store'
// use inter font
import '@fontsource/inter/latin.css'

import { faClipboard } from '@fortawesome/free-regular-svg-icons'
// add font awesome icons
import {
  faAnchor,
  faArrowRight,
  faArrowRotateRight,
  faBan,
  faBars,
  faBicycle,
  faBolt,
  faBriefcase,
  faCalendarCheck,
  faCamera,
  faChartPie,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faClipboardCheck,
  faClock,
  faComments,
  faCube,
  faEdit,
  faExpandAlt,
  faFilter,
  faFlask,
  faGift,
  faHandshake,
  faIndianRupeeSign,
  faKey,
  faMapMarker,
  faMapMarkerAlt,
  faMinus,
  faPlus,
  faPowerOff,
  faPumpSoap,
  faRedo,
  faSignOutAlt,
  faSkullCrossbones,
  faStopCircle,
  faStore,
  faTicket,
  faTimes,
  faTools,
  faTrash,
  faTreeCity,
  faUndo,
  faUnlock,
  faUser,
  faUserGear,
  faWarehouse,
  faWifi,
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faWifi,
  faAnchor,
  faMapMarker,
  faMapMarkerAlt,
  faUser,
  faIndianRupeeSign,
  faChartPie,
  faBicycle,
  faBriefcase,
  faComments,
  faRedo,
  faBolt,
  faClock,
  faStopCircle,
  faTrash,
  faUndo,
  faEdit,
  faChevronLeft,
  faChevronRight,
  faBars,
  faClipboard,
  faBan,
  faPowerOff,
  faUnlock,
  faSignOutAlt,
  faArrowRight,
  faHandshake,
  faTimes,
  faTools,
  faKey,
  faPumpSoap,
  faCalendarCheck,
  faCamera,
  faExpandAlt,
  faCheck,
  faFlask,
  faCircleInfo,
  faCirclePlus,
  faCircleMinus,
  faPlus,
  faMinus,
  faTreeCity,
  faSkullCrossbones,
  faCube,
  faUserGear,
  faGift,
  faArrowRotateRight,
  faFilter,
  faTicket,
  faClipboardCheck,
  faStore,
  faWarehouse,
)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

const isProd = import.meta.env.MODE === 'production'
Vue.config.productionTip = false

// enable sentry error monitoring
if (isProd) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/zeus\.tilt\.bike/],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
  })
}

// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  firstDayOfWeek: 2, // Monday
})

// use vue-clipboard2 plugin
Vue.use(VueClipboard)

// use vue-load-script-plugin
Vue.use(LoadScript)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
