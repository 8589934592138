/*
    Auth
*/

export const LOGIN_MUTATION = `
  mutation ($input: LoginAdminInput!) {
    loginAdmin(input: $input) {
      admin {
        id
        blocked
        permissions {
          permission {
            id
            attribute
            description
          }
        }
      }
      token
    }
  }
`

export const ADMIN_PROFILE_QUERY = `
  query {
    currentAdmin {
      id
      blocked
      permissions {
        permission {
          id
          attribute
          description
        }
      }
    }
  }
`

/*
    Pass
*/

export const PASSES_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $status: String, $type: PassTypeEnum, $ecosystem: String, $noEcosystemsFilter: Boolean, $isUpgrade: Boolean, $missingUpgradePass: Boolean) {
    passes (
      after: $after
      first: $first,
      before: $before,
      last: $last,
      status: $status
      type: $type
      ecosystem: $ecosystem
      noEcosystemsFilter: $noEcosystemsFilter
      isUpgrade: $isUpgrade
      missingUpgradePass: $missingUpgradePass
    ) {
      edges {
        node {
          id
          type
          status
          duration
          durationText
          extraDuration
          extraDurationText
          dailyDurationLimit
          cost
          strikeCost
          includedDurationPerRide
          tag
          totalDuration
          savePercentText
          promoId
          isUpgrade
          createdAt
          updatedAt
          ecosystems {
            ecosystem {
              id
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const PASS_QUERY = `
  query ($id: Int!) {
    pass (
      id: $id
    ) {
      id
      type
      status
      dailyDurationLimit
      isUpgrade
      ecosystems {
        id
        ecosystem {
          id
          name
        }
      }
    }
  }
`

export const ECOSYSTEMS_WITHOUT_PASSES_QUERY = `
  query {
    ecosystemsWithoutPasses {
      id
      name
    }
  }
`

export const CREATE_PASS_MUTATION = `
  mutation ($input: CreatePassInput!) {
    createPass (input: $input) {
      id
    }
  }
`

export const ADD_PASS_ECOSYSTEMS_MUTATION = `
  mutation ($input: AddPassEcosystemsInput!) {
    addPassEcosystems (input: $input) {
      count
    }
  }
`

export const REMOVE_PASS_ECOSYSTEMS_MUTATION = `
  mutation ($input: RemovePassEcosystemsInput!) {
    removePassEcosystems (input: $input) {
      count
    }
  }
`

export const TOGGLE_PASS_STATUS_MUTATION = `
  mutation ($input: TogglePassStatusInput!) {
    togglePassStatus (input: $input) {
      id
      status
    }
  }
`

/*
    Ecosystem
*/

export const ECOSYSTEMS_QUERY = `
  query {
    ecosystems {
      id
      name
      slug
      paymentModel
      status
      region
    }
  }
`

export const PAGINATED_ECOSYSTEMS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $slug: String, $region: String, $hub: String, $status: String) {
    paginatedEcosystems (
      after: $after
      first: $first,
      before: $before,
      last: $last,
      slug: $slug
      region: $region
      hub: $hub
      status: $status
    ) {
      edges {
        node {
          id
          slug
          name
          region
          hub
          ridePricingPlan
          electricBikesEnabled
          operatorCallsAllowed
          paymentModel
          status
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const CREATE_ECOSYSTEM_MUTATION = `
  mutation ($input: CreateEcosystemInput!) {
    createEcosystem (input: $input) {
      id
      name
    }
  }
`

export const UPDATE_ECOSYSTEM_MUTATION = `
  mutation ($input: UpdateEcosystemInput!) {
    updateEcosystem (input: $input) {
      id
      name
    }
  }
`

/*
    Station
*/

export const STATIONS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $id: Int, $dockQrIdentifier: String, $offlineDocks: Boolean, $state: [String], $ecosystem: String) {
    stations(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      id: $id,
      dockQrIdentifier: $dockQrIdentifier,
      offlineDocks: $offlineDocks,
      state: $state
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          name
          ecosystem {
            name
            slug
          }
          docks {
            id
            deviceId
            address
            latchState
            dockState
            status
            qrIdentifier
            updatedAt
            bike {
              number
              type
            }
          }
          latitude
          longitude
          state
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const DOCK_QUERY = `
  query ($id: Int, $qrIdentifier: String) {
    dock (id: $id, qrIdentifier: $qrIdentifier) {
      id
      deviceId
      qrIdentifier
      address
      latchState
      dockState
      status
      createdAt
      updatedAt
      lastErrorAt
    }
  }
`

export const UPDATE_STATION_DETAILS_MUTATION = `
  mutation ($input: UpdateStationDetailsInput!) {
    updateStationDetails(input: $input) {
      id
      name
    }
  }
`

export const RESTART_DOCK = `
  mutation ($input: RestartDockInput!) {
    restartDock(input: $input) {
      id
      name
    }
  }
`

export const TRIGGER_DOCK = `
  mutation ($input: TriggerDockInput!) {
    triggerDock(input: $input) {
      id
      name
    }
  }
`

export const CREATE_STATION_MUTATION = `
  mutation ($input: CreateStationInput!) {
    createStation (input: $input) {
      id
      name
    }
  }
`

export const UPDATE_DOCK_STATUS_MUTATION = `
  mutation ($input: UpdateDockStatusInput!) {
    updateDockStatus (input: $input) {
      id
      address
      status
    }
  }
`

/*
    Ongoing rides
*/

export const ONGOING_RIDES_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $userId: Int, $ecosystem: String) {
    ongoingRides(
      after: $after
      first: $first
      before: $before
      last: $last
      userId: $userId
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          user {
            id
            name
            phoneNumber
          }
          bike {
            id
            number
            type
          }
          fromStation {
            id
            name
          }
          startTime
          method
          userPass {
            id
          }
          ecosystem {
            name
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const STOP_RIDE_STATIONS_QUERY = `
  query ($first: Int!, $state: [String], $ecosystem: String!) {
    stations(
      first: $first
      state: $state
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          name
          ecosystem {
            name
            slug
          }
        }
      }
    }
  }
`

export const STOP_RIDE_MUTATION = `
  mutation ($input: StopOngoingRideInput!) {
    stopOngoingRide(input: $input) {
      id
      bike {
        number
      }
    }
  }
`

export const DELETE_ONGOING_RIDE_MUTATION = `
  mutation ($input: DeleteOngoingRideInput!) {
    deleteOngoingRide(input: $input) {
      id
      bike {
        number
      }
    }
  }
`

/*
    Completed rides
*/

export const COMPLETED_RIDES_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $userId: Int, $bikeId: Int, $durationFilter: DurationFilter, $state: [String], $minDate: DateTime, $maxDate: DateTime, $ecosystem: String) {
    rides(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      userId: $userId
      bikeId: $bikeId
      durationFilter: $durationFilter
      state: $state
      minDate: $minDate
      maxDate: $maxDate
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          user {
            id
            name
          }
          bike {
            id
            number
            type
          }
          fromStation {
            name
          }
          toStation {
            name
          }
          startTime
          endTime
          duration
          cost
          creditsCost
          promoCreditsCost
          rideRating {
            id
            rating
            type
            issues {
              name
            }
            photos
            comment
          }
          method
          state
          userPass {
            id
          }
          ecosystem {
            name
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const ADJUST_RIDE_MUTATION = `
  mutation ($input: AdjustRideInput!) {
    adjustRide (input: $input) {
      id
    }
  }
`

export const REFUND_RIDE_MUTATION = `
  mutation ($input: RefundRideInput!) {
    refundRide (input: $input) {
      id
    }
  }
`

/*
    Users
*/

export const USERS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $minDate: DateTime, $maxDate: DateTime, $id: Int, $phoneNumber: String, $email: String, $ecosystem: String) {
    users(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      minDate: $minDate
      maxDate: $maxDate
      id: $id
      phoneNumber: $phoneNumber
      email: $email
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          name
          phoneNumber
          email
          gender
          avatar
          ecosystem {
            name
            slug
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const TOGGLE_TEST_USER_MUTATION = `
  mutation ($input: ToggleTestUserInput!) {
    toggleTestUser(input: $input) {
      id
      test
    }
  }
`

export const BLOCK_USER_MUTATION = `
  mutation ($input: BlockUserInput!) {
    blockUser(input: $input) {
      id
    }
  }
`

export const UNBLOCK_USER_MUTATION = `
  mutation ($input: UnblockUserInput!) {
    unblockUser(input: $input) {
      id
    }
  }
`

/*
    User profile
*/

export const USER_PROFILE_QUERY = `
  query ($id: Int!) {
    users(id: $id) {
      edges {
        node {
          id
          name
          phoneNumber
          email
          gender
          birthDate
          avatar
          level
          score
          verified
          verifiedEmail
          referralCode
          whatsappOpted
          test
          createdAt
          updatedAt
          blocked
          blockedAt
          blockReason
          credits
          promoCredits
          totalCredits
          coins
          firstRecharge
          paymentModel
          totalRides
          totalRideDuration
          totalRecharges
          totalRechargeAmount
          totalReferrals
          totalPasses
          passes(first: 1, status: "ORIGINAL") {
            edges {
              node {
                id
                startsAt
                expiresAt
                totalRides
                totalRideDuration
                status
                pass {
                  id
                  type
                  status
                  duration
                  durationText
                  extraDuration
                  extraDurationText
                  cost
                  strikeCost
                  dailyDurationLimit
                  includedDurationPerRide
                  tag
                  totalDuration
                  savePercentText
                  promoId
                  isUpgrade
                  createdAt
                  updatedAt
                }
              }
            }
            totalCount
          }
          ecosystem {
            name
            slug
            paymentModel
          }
        }
      }
    }
  }
`

export const CREATE_VOUCHER_PAYMENT_MUTATION = `
  mutation ($input: CreateVoucherPaymentInput!) {
    createVoucherPayment(input: $input) {
      id
      amount
      credits
      user {
        credits
        promoCredits
        totalCredits
      }
    }
  }
`

export const CREATE_DEBIT_PAYMENT_MUTATION = `
  mutation ($input: CreateDebitPaymentInput!) {
    createDebitPayment(input: $input) {
      id
      amount
      credits
      user {
        credits
        promoCredits
        totalCredits
      }
    }
  }
`

/*
    Payments
*/

export const PAYMENTS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $orderId: String, $userId: Int, $minDate: DateTime, $maxDate: DateTime, $status: String, $crdr: [String], $type: [String], $mode: [String], $ecosystem: String) {
    payments(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      orderId: $orderId
      userId: $userId
      minDate: $minDate
      maxDate: $maxDate
      crdr: $crdr
      type: $type
      mode: $mode
      status: $status
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          user {
            id
            name
          }
          orderId
          crdr
          amount
          type
          mode
          status
          issuer
          razorpayPaymentId
          voucherReason
          debitReason
          issuerAdmin {
            id
            name
          }
          ecosystem {
            name
            slug
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const PAYMENT_QUERY = `
  query ($id: Int!) {
    payment(id: $id) {
      id
      orderId
      crdr
      amount
      credits
      type
      mode
      status
      issuer
      razorpayPaymentId
      razorpayOrderId
      razorpayRefundId
      ecosystem {
        id
        name
      }
      createdAt
      updatedAt
      issuerAdmin {
        name
      }
      voucherReason
      debitReason
      pass {
        id
        startsAt
        expiresAt
        totalRides
        totalRideDuration
        status
        paymentAmount
        debitPaymentAmount
        pass {
          id
          type
          status
          duration
          durationText
          extraDuration
          extraDurationText
          cost
          strikeCost
          dailyDurationLimit
          includedDurationPerRide
          tag
          totalDuration
          savePercentText
          promoId
          isUpgrade
          createdAt
          updatedAt
        }
      }
      passDebit {
        id
        startsAt
        expiresAt
        totalRides
        totalRideDuration
        status
        paymentAmount
        debitPaymentAmount
        pass {
          id
          type
          status
          duration
          durationText
          extraDuration
          extraDurationText
          cost
          strikeCost
          dailyDurationLimit
          includedDurationPerRide
          tag
          totalDuration
          savePercentText
          promoId
          isUpgrade
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const CAPTURE_RAZORPAY_PAYMENT_MUTATION = `
  mutation ($input: CaptureRazorpayPaymentInput!) {
    captureRazorpayPayment(input: $input) {
      orderId
      amount
      credits
      status
      razorpayPaymentId
      ecosystem {
        name
        slug
      }
    }
  }
`

export const REFUND_ONLINE_PAYMENT_MUTATION = `
  mutation ($input: RefundOnlinePaymentInput!) {
    refundOnlinePayment(input: $input) {
      orderId
      amount
    }
  }
`

export const REFUND_PASS_PURCHASE_MUTATION = `
  mutation ($input: RefundPassPurchaseInput!) {
    refundPassPurchase(input: $input) {
      id
      paymentAmount
      debitPaymentAmount
    }
  }
`

/*
    Operator Logs
*/

export const OPERATOR_LOGS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $type: OperatorLogTypeEnum, $operatorId: Int, $minDate: DateTime, $maxDate: DateTime, $ecosystem: String) {
    operatorLogs(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      type: $type
      operatorId: $operatorId
      minDate: $minDate
      maxDate: $maxDate
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          type
          operator {
            id
            name
          }
          station {
            id
            name
            latitude
            longitude
          }
          bikes {
            bike {
              id
              number
            }
          }
          photos
          latitude
          longitude
          isAudited
          auditedAt
          createdAt
          ecosystem {
            name
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/*
    Docks
*/

export const DOCKS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $deviceId: String, $qrIdentifier: String, $stationId: Int, $ecosystem: String) {
    docks(
      after: $after
      first: $first
      before: $before
      last: $last
      deviceId: $deviceId
      qrIdentifier: $qrIdentifier
      stationId: $stationId
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          deviceId
          qrIdentifier
          address
          station {
            id
            name
          }
          ecosystem {
            id
            name
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const CREATE_DOCK_MUTATION = `
  mutation ($input: CreateDockInput!) {
    createDock(input: $input) {
      id
      address
    }
  }
`

export const UPDATE_DOCK_MUTATION = `
  mutation ($input: UpdateDockInput!) {
    updateDock(input: $input) {
      id
      address
      station {
        name
      }
    }
  }
`

export const DELETE_DOCK_MUTATION = `
  mutation ($input: DeleteDockInput!) {
    deleteDock(input: $input) {
      id
    }
  }
`

/*
    Bikes
*/

export const BIKES_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $id: Int, $number: String, $hexcode: String, $state: [String], $ecosystem: String) {
    bikes(
      after: $after
      first: $first
      before: $before
      last: $last
      id: $id
      number: $number
      hexcode: $hexcode
      state: $state
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          hexcode
          number
          type
          state
          ecosystem {
            name
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const UPDATE_BIKE_HEXCODE_MUTATION = `
  mutation ($input: UpdateBikeHexcodeInput!) {
    updateBikeHexcode(input: $input) {
      id
    }
  }
`

export const UPDATE_BIKE_STATE_MUTATION = `
  mutation ($input: UpdateBikeStateInput!) {
    updateBikeState(input: $input) {
      id
      number
      type
      state
      ecosystem {
        name
        slug
      }
    }
  }
`

export const UPDATE_BIKE_ECOSYSTEM_MUTATION = `
  mutation ($input: UpdateBikeEcosystemInput!) {
    updateBikeEcosystem(input: $input) {
      id
      number
      ecosystem {
        name
      }
    }
  }
`

export const PENDING_BIKES_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $ecosystem: String) {
    pendingBikes(
      after: $after
      first: $first
      before: $before
      last: $last
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          station {
            id
            name
          }
          dockNumber
          hexcode
          ecosystem {
            name
            slug
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const DELETE_ALL_PENDING_BIKES_MUTATION = `
  mutation {
    deleteAllPendingBikes {
      count
    }
  }
`

export const DEPLOY_PENDING_BIKE_MUTATION = `
  mutation ($input: DeployPendingBikeInput!) {
    deployPendingBike(input: $input) {
      id
    }
  }
`

export const DELETE_PENDING_BIKE_MUTATION = `
  mutation ($input: DeletePendingBikeInput!) {
    deletePendingBike(input: $input) {
      id
    }
  }
`

export const CREATE_BIKE_MUTATION = `
  mutation ($input: CreateBikeInput!) {
    createBike(input: $input) {
      id
      number
    }
  }
`

/*
    Ride ratings
*/

export const RIDE_RATINGS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $userId: Int, $onlyBad: Boolean, $minDate: DateTime, $maxDate: DateTime, $ecosystem: String) {
    rideRatings(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      userId: $userId
      onlyBad: $onlyBad
      minDate: $minDate
      maxDate: $maxDate
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          user {
            id
            name
          }
          ride {
            id
            startTime
            bike {
              id
              number
              type
            }
            toStation {
              name
            }
          }
          rating
          type
          issues {
            name
          }
          photos
          comment
          ecosystem {
            name
            slug
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/*
    Tasks
*/

export const TASKS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $id: Int, $creatorUserId: Int, $bikeNumber: String, $minDate: DateTime, $maxDate: DateTime, $ecosystems: [String], $type: TaskTypeEnum, $status: String, $creatorRole: String, $assigned: Boolean, $assignmentRole: TaskAssignmentRoleEnum, $assignedOperatorId: Int, $issues: [String]) {
    tasks(
      after: $after
      first: $first,
      before: $before,
      last: $last,
      id: $id
      creatorUserId: $creatorUserId
      bikeNumber: $bikeNumber
      minDate: $minDate
      maxDate: $maxDate
      ecosystems: $ecosystems
      type: $type
      status: $status
      creatorRole: $creatorRole
      assigned: $assigned
      assignmentRole: $assignmentRole
      assignedOperatorId: $assignedOperatorId
      issues: $issues
    ) {
      edges {
        node {
          id
          type
          status
          assignmentRole
          assignedOperator {
            id
            name
            role
          }
          creatorComment
          issues {
            type
            name
            identifier
            accepted
          }
          creatorPhotos
          creatorUser {
            id
            name
          }
          creatorOperator {
            id
            role
            name
          }
          creatorAdmin {
            id
            name
          }
          bike {
            id
            number
            type
          }
          station {
            id
            name
          }
          dockAddress
          ride {
            startTime
            duration
            rideRating {
              rating
            }
          }
          resolverOperator {
            id
            role
            name
          }
          resolverAdmin {
            id
            name
          }
          resolverPhotos
          resolverComment
          ecosystem {
            name
            slug
          }
          closedAt
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const TASK_ISSUES_QUERY = `
  query {
    taskIssues {
      id
      type
      identifier
      name
      isActive
      creatorRoles
    }
  }
`

export const TASKS_STATS_QUERY = `
  query ($ecosystems: [String], $type: TaskTypeEnum, $issues: [String]) {
    tasksStats(ecosystems: $ecosystems, type: $type, issues: $issues) {
      open
      assigned
      unassigned
    }
  }
`

export const CREATE_TASK_MUTATION = `
  mutation ($input: CreateTaskInput!) {
    createTask(input: $input) {
      taskIds
    }
  }
`

export const TASK_PHOTO_URL_MUTATION = `
  mutation {
    createTaskPhotoUrl {
      fileName
      url
    }
  }
`

export const UPDATE_TASK_MUTATION = `
  mutation ($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      status
    }
  }
`

export const ASSIGN_TASKS_MUTATION = `
  mutation ($input: AssignTasksInput!) {
    assignTasks(input: $input) {
      count
    }
  }
`

export const DELETE_TASKS_MUTATION = `
  mutation ($input: DeleteTasksInput!) {
    deleteTasks(input: $input) {
      count
    }
  }
`

/*
    Referrals
*/

export const REFERRALS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $userId: Int, $minDate: DateTime, $maxDate: DateTime, $ecosystem: String) {
    referrals(
      after: $after
      first: $first
      before: $before
      last: $last
      userId: $userId
      minDate: $minDate
      maxDate: $maxDate
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          referrer {
            id
            name
          }
          redeemer {
            id
            name
          }
          referrerReward {
            amount
            state
          }
          redeemerReward {
            amount
            state
          }
          ecosystem {
            name
            slug
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/*
    Rewards
*/

export const REWARDS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $userId: Int, $minDate: DateTime, $maxDate: DateTime, $state: String, $type: String, $ecosystem: String) {
    rewards(
      after: $after
      first: $first
      before: $before
      last: $last
      userId: $userId
      minDate: $minDate
      maxDate: $maxDate
      state: $state
      type: $type
      ecosystem: $ecosystem
    ) {
      edges {
        node {
          id
          user {
            id
            name
          }
          amount
          coins
          type
          state
          description
          ecosystem {
            name
            slug
          }
          redeemedOn
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

/*
    Analytics
*/

export const CUMULATIVE_ANALYTICS_QUERY = `
  query($minDate: DateTime, $maxDate: DateTime, $ecosystem: String) {
    analytics(
      minDate: $minDate
      maxDate: $maxDate
      ecosystem: $ecosystem
    ) {
      users {
        registeredUsers
        totalCredits
        avgCredits
      }
      rides {
        totalRides
        fitnessRides
        electricRides
        totalDuration
        fitnessDuration
        electricDuration
        avgDuration
        totalRevenue
        avgRevenue
        avgRating
        originalRides
        adjustedRides
        refundedRides
        uniqueUsers
        uniqueBikes
        durationDistribution
        stationDistribution
      }
      payments {
        successful {
          count
          amount
        }
        refunded {
          count
          amount
        }
        failed {
          count
          amount
        }
        passes {
          count
          amount
        }
        registrations {
          count
          amount
        }
        recharges {
          count
          amount
        }
        subscriptions {
          count
          amount
        }
        cards {
          count
          amount
        }
        online {
          count
          amount
        }
        voucher {
          count
          amount
        }
        cash {
          count
          amount
        }
      }
    }
  }
`

/*
    Operators
*/

export const OPERATORS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $id: Int, $phoneNumber: String, $name: String, $ecosystem: String, $role: OperatorRoleEnum, $blocked: Boolean) {
    operators(
      after: $after
      first: $first
      before: $before
      last: $last
      id: $id
      phoneNumber: $phoneNumber
      name: $name
      ecosystem: $ecosystem
      role: $role
      blocked: $blocked
    ) {
      edges {
        node {
          id
          role
          name
          phoneNumber
          avatar
          ecosystems {
            ecosystem {
              name
              slug
            }
            active
          }
          createdAt
          blocked
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const UPDATE_OPERATOR_ROLE_MUTATION = `
  mutation ($input: UpdateOperatorRoleInput!) {
    updateOperatorRole(input: $input) {
      id
      name
      role
    }
  }
`

export const CREATE_OPERATOR_MUTATION = `
  mutation ($input: CreateOperatorInput!) {
    createOperator(input: $input) {
      id
      name
    }
  }
`

/*
    Operator profile
*/

export const OPERATOR_PROFILE_QUERY = `
  query ($id: Int!) {
    operators(id: $id) {
      edges {
        node {
          id
          role
          name
          phoneNumber
          avatar
          ecosystems {
            ecosystem {
              id
              name
              slug
            }
            active
          }
          createdAt
          updatedAt
          blocked
        }
      }
    }
  }
`

export const BLOCK_OPERATOR_MUTATION = `
  mutation ($input: BlockOperatorInput!) {
    blockOperator(input: $input) {
      id
    }
  }
`

export const UNBLOCK_OPERATOR_MUTATION = `
  mutation ($input: UnblockOperatorInput!) {
    unblockOperator(input: $input) {
      id
    }
  }
`

export const ADD_OPERATOR_ECOSYSTEM_MUTATION = `
  mutation ($input: AddOperatorEcosystemInput!) {
    addOperatorEcosystem(input: $input) {
      id
      name
      phoneNumber
      avatar
      ecosystems {
        ecosystem {
          id
          name
          slug
        }
        active
      }
      createdAt
      updatedAt
      blocked
    }
  }
`

export const DELETE_OPERATOR_ECOSYSTEM_MUTATION = `
  mutation ($input: DeleteOperatorEcosystemInput!) {
    deleteOperatorEcosystem(input: $input) {
      id
      name
      phoneNumber
      avatar
      ecosystems {
        ecosystem {
          id
          name
          slug
        }
        active
      }
      createdAt
      updatedAt
      blocked
    }
  }
`

/*
    Audit operator log
*/

export const OPERATOR_LOG_FOR_AUDIT_QUERY = `
  query {
    operatorLogForAudit {
      id
      operator {
        name
      }
      station {
        name
      }
      bikes {
        bike {
          number
        }
      }
      photos
      createdAt
      ecosystem {
        name
      }
    }
  }
`

export const AUDIT_OPERATOR_LOG_MUTATION = `
  mutation ($input: AuditOperatorLogInput!) {
    auditOperatorLog(input: $input) {
      id
    }
  }
`

/*
    Admins
*/

export const ADMINS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $email: String, $blocked: Boolean) {
    admins(
      after: $after
      first: $first
      before: $before
      last: $last
      email: $email
      blocked: $blocked
    ) {
      edges {
        node {
          id
          name
          email
          blocked
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const ADMIN_PERMISSIONS_QUERY = `
  query {
    adminPermissions {
      id
      attribute
      description
    }
  }
`

export const CREATE_ADMIN_PERMISSION_MUTATION = `
  mutation ($input: CreateAdminPermissionInput!) {
    createAdminPermission(input: $input) {
      id
      attribute
      description
    }
  }
`

export const ADMIN_QUERY = `
  query ($id: Int!) {
    admins(id: $id) {
      edges {
        node {
          id
          name
          email
          blocked
          createdAt
          updatedAt
          permissions {
            permission {
              id
              attribute
              description
            }
          }
        }
      }
    }
  }
`

export const ADD_ADMIN_PERMISSION_MUTATION = `
  mutation ($input: AddAdminPermissionInput!) {
    addAdminPermission(input: $input) {
      id
    }
  }
`

export const REMOVE_ADMIN_PERMISSION_MUTATION = `
  mutation ($input: RemoveAdminPermissionInput!) {
    removeAdminPermission(input: $input) {
      id
    }
  }
`

export const BLOCK_ADMIN_MUTATION = `
  mutation ($input: BlockAdminInput!) {
    blockAdmin(input: $input) {
      id
      name
    }
  }
`

export const UNBLOCK_ADMIN_MUTATION = `
  mutation ($input: UnblockAdminInput!) {
    unblockAdmin(input: $input) {
      id
      name
    }
  }
`

/*
    Store deals
*/

export const STORE_DEALS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $status: String) {
    storeDeals(after: $after, first: $first, before: $before, last: $last, status: $status) {
      edges {
        node {
          id
          status
          ssCouponId
          ssShopName
          ssShopLink
          brandName
          brandUrl
          shortDescription
          category
          headerImageUrl
          description
          terms
          price
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const STORE_DEAL_QUERY = `
  query ($id: Int!) {
    storeDeal(id: $id) {
      id
      status
      ssCouponId
      ssShopName
      ssShopLink
      brandName
      brandUrl
      shortDescription
      category
      headerImageUrl
      description
      terms
      price
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_STORE_DEAL_STATUS_MUTATION = `
  mutation ($input: UpdateStoreDealStatusInput!) {
    updateStoreDealStatus(input: $input) {
      id
      status
    }
  }
`

/*
    Inventory
*/

export const INVENTORY_PRODUCTS_QUERY = `
  query ($status: InventoryStatus) {
    inventoryProducts(status: $status) {
      id
      name
      category
      type
      stock
    }
  }
`

export const INVENTORY_LOCATIONS_QUERY = `
  query {
    inventoryLocations {
      bins {
        id
        number
        type
        rack {
          number
          warehouse {
            name
          }
        }
      }
      ecosystems {
        id
        name
        slug
        region
      }
      operators {
        id
        name
      }
      vendors {
        id
        name
      }
      warehouses {
        id
        name
        region
      }
    }
  }
`

export const INVENTORY_PRODUCT_STOCK_QUERY = `
  query ($productId: Int!, $ecosystemId: Int, $operatorId: Int, $binId: Int, $warehouseId: Int) {
    inventoryProductStock(productId: $productId, ecosystemId: $ecosystemId, operatorId: $operatorId, binId: $binId, warehouseId: $warehouseId) {
      count
    }
  }
`

export const CREATE_INVENTORY_ENTRY_MUTATION = `
  mutation ($input: CreateInventoryEntryInput!) {
    createInventoryEntry(input: $input) {
      id
    }
  }
`

export const INVENTORY_LEDGER_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int, $minDate: DateTime, $maxDate: DateTime) {
    inventoryLedger(after: $after, first: $first, before: $before, last: $last, minDate: $minDate, maxDate: $maxDate) {
      edges {
        node {
          id
          createdAt
          creatorAdmin {
            id
            name
          }
          creatorOperator {
            id
            name
          }
          transactionId
          transactionReference
          bin {
            id
            number
            type
            status
            createdAt
            updatedAt
            rack {
              id
              number
              warehouse {
                name
              }
            }
          }
          ecosystem {
            id
            name
          }
          operator {
            id
            name
          }
          vendor {
            id
            name
            status
            createdAt
            updatedAt
          }
          warehouse {
            id
            name
            region
            status
            createdAt
            updatedAt
            racks {
              id
              number
            }
          }
          product {
            id
            finaleId
            name
            category
            type
            status
            createdAt
            updatedAt
          }
          quantity
          consumedBike {
            id
            number
          }
          comments
          approvedAt
          approvedAdmin {
            id
            name
          }
          deletedAt
          deletedAdmin {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`
